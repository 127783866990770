import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Autre services</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => {
                const parts = d.text.split("Contactez-nous");
                return (
                  <div key={`${d.name}-${i}`} className="col-md-12" style={{ marginBottom: '20px' }}>
                    <div className="col-xs-12 col-md-6">
                      <img src={d.img} className="img-responsive" alt="" />
                    </div>
                    <div className="about-text" style={{ marginTop: '20px' }}>
                      <h3>{d.name}</h3>
                      <p>
                        {parts.map((part, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <a href="#contact" style={{ color: 'white' }}>Contactez-nous</a>}
                            {part}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </div>
                );
              })
            : "loading"}
        </div>
      </div>
    </div>
  );
};